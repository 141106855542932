<template>
  <div>
    <div class="frame has-text-centered">
      <Brand
        type="image"
        width="150"
        class="brand"
        @click="go('/')"
      />
      <h3 class="title is-5">{{ deal.business.name }}</h3>
      <p class="subtitle is-6">{{ deal.description }}</p>

      <qrcode-vue :value="dealQrUrl" :size="150"></qrcode-vue>

      <div class="section">
        <p>Upon completing purchase, present card to customer to scan using the <Brand /> app.</p>
      </div>
    </div>
    <div class="mt-5 has-text-centered">
      <p class="mt-4">Please print and cut along the dashed line.</p>
      <button 
        @click="back()"
        class="button">
        Back to Deal
      </button>
    </div>
  </div>
</template>
<script>
import Brand from "@/components/Brand.vue";
import QrcodeVue from "qrcode.vue";

export default {
  props: {
    orgSlug: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    locationId: {
      type: String,
      required: true
    }
  },
  components: {
    Brand,
    QrcodeVue
  },
  data() {
    return {
      deal: {
        description: 'asdasdasd',
        code: '',
        business: {
          name: 'asdasd'
        }
      }
    };
  },
  computed: {
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;
    },
    dealQrUrl: function() {
      return (this.deal && this.deal.code) ? `https://${location.host}/deal/${this.deal.code}` : ""
    }
  },
  mounted() {
    this.loading = true;
    if ((this.currentBusiness
      && this.currentBusiness.slug
      && this.currentDeal
      && this.currentDeal.slug
      && this.currentDeal.business.slug == this.currentBusiness.slug)
    ) {
      this.deal = this.currentDeal
    } else {
      this.back()
    }
    this.loading = false
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  }
};
</script>

<style scoped>
.frame {
  width: 2.5in;
  height: 4in;
  font-size: 50%;
  margin: 0 auto;
  border: 1px dashed #000;
}
.frame .brand {
  margin: 1rem 0;
}
.frame .section {
  padding: 1rem;
}
button {
  margin-top: 1.5rem;
}
</style>
